import { getAPICall } from "../APIMethods/APIMethods";
import { Url } from "../EnvoirnmentSetup/APIs";
import { protocol } from "../EnvoirnmentSetup/profiles";
// import moment from "moment-timezone";

export const getPremiumMemberUserInfo = async () => {
  let membershipStatus = new Promise(async (resolve, reject) => {
    getAPICall(Url.MemberShipStatus)
      .then((res) => {
        if (res.SUCCESS) {
          if (!!res.DATA) {
            resolve(res.DATA)
          } else {
            resolve(res.DATA)
            alert("Your premium membership is expired")
          }
        }
      })
  })
  return membershipStatus
}

export const ExcelDownload = async (api, fileName) => {
  try {
    const response = await fetch(api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': "Bearer " + sessionStorage.getItem("token"),
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Get the response as a Blob
    const blob = await response.blob();
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName ? fileName + '.xlsx' : 'untitled.xlsx'; // Set the desired file name
    document.body.appendChild(a);
    a.click();
    // Clean up
    a.remove();
    window.URL.revokeObjectURL(url);
    return true
  } catch (error) {
    console.error('Download failed', error);
    return false
  }
}
export const modifyTimezoneString = (timeString, formate) => {
  //formate eg. "MM/DD/YYYY HH:mm A" etc
  // const parsedDateTime = moment(timeString);
  // const newTimeString = parsedDateTime.local();
  // let str=newTimeString.format(formate);
  // return str;
}


export const getUserAgent = () => navigator.userAgent;

export const handleCopyDetails = (text) => {
  try {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    if (getUserAgent().match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
    document.execCommand('copy');
    document.body.removeChild(textArea);

    // alert('linked copy')
    return true
  } catch (err) {
    console.log(err);
    // alert('Link Copy failed')
    return false;
  }
}

export const getFullUrl = (url = '') => {
  let updatedLink;
  if (!!!url) return null
  if (url?.trim()?.startsWith(`${protocol}://www.`) || url?.trim()?.startsWith(`${protocol}s://www.`)) {
    updatedLink = url?.trim()
  } else if (url?.trim()?.startsWith("www.")) {
    updatedLink = `${protocol}s://${url?.trim()}`
  } else if (url?.trim()?.startsWith(`${protocol}s://`) || url?.trim()?.startsWith(`${protocol}://`)) {
    let newLink = url?.trim()
    let colIndex = newLink.indexOf(":") + 3
    let lastIndex = newLink.length
    let strAfterRemove = newLink.slice(colIndex, lastIndex)
    updatedLink = `${protocol}s://www.${strAfterRemove}`
  } else {
    updatedLink = `${protocol}s://www.${url?.trim()}`
  }
  return updatedLink
}