import React from 'react'
import './HeroSection.css'
import Employees from '../../assets/Employees.png'
import bannerOne from '../../assets/banner1.png'
import bannerTwo from '../../assets/banner2.png'
import bannerThree from '../../assets/banner3.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
const HeroSection = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    let userName = JSON.parse(sessionStorage.getItem('userName'))
    return (
        <div className='heroSectionMainDiv'>
            <div className='applicantHeroSection'>
                <div className='applicantTextDiv'>
                    <span className='helloText'>Hello, </span>
                    <h2 className='nameText' title={userName}>{userName}</h2>
                    <span className='roleText'>Applicant</span>
                </div>
                <div className='applicantEmployeeDiv'>
                    <img src={Employees} alt="" />
                </div>
            </div>
            {/* <div className='applicantHeroSection'>
            <div className='applicantTextDiv'>
                <span className='helloText'>Hello, </span>
                <h2 className='nameText'>John Doe</h2>
                <span className='roleText'>Applicant</span>
            </div>
            <div className='applicantEmployeeDiv'>
            <img src={Employees} alt="" />
            </div>
        </div> */}
            <div className='applicantSliderHeroSection'>
                {/* <div className="sliderSectionOne">
                <img src={bannerOne} alt="" />
            </div>
            <div className="sliderSectionOne">
                <img src={bannerTwo} alt="" />
            </div>
            <div className="sliderSectionOne">
                <img src={bannerThree} alt="" />
            </div> */}
                <Slider {...settings}>
                    <div className="sliderSectionOne">
                        <img src={bannerOne} alt="Banner One" />
                    </div>
                    <div className="sliderSectionOne">
                        <img src={bannerTwo} alt="Banner Two" />
                    </div>
                    <div className="sliderSectionOne">
                        <img src={bannerThree} alt="Banner Three" />
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default HeroSection