import "./Notification.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Url } from "../../EnvoirnmentSetup/APIs";
import React, { useEffect, useState } from "react";
import { useSelectedCardContext } from "../../Context/Context";
import { getAPICall, putAPICall } from "../../APIMethods/APIMethods";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";

import nodata from "../../assets/nodata.png";
import caenderLogo from "../../assets/dategray.png";
import locationLogo from "../../assets/location.png";
import deleteRoundLogo from "../../assets/deleteround.png";
import experienceLogo from "../../assets/experiencegray.png";

const Notification = () => {
  const navigate = useNavigate();
  const { setSelectedCard, IsLoading, setIsLoading, setWarning, setAlert } = useSelectedCardContext()
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
    setSelectedCard(0)
  }, []);


  const getNotifications = () => {
    setIsLoading(true);
    getAPICall(Url?.GetNotifications)
      .then((res) => {
        setIsLoading(false);
        if (res?.SUCCESS) {
          let changedData = res?.DATA.map((i) => {
            let salaryWithCurrency = !!i?.job?.salary
              ? i?.job?.countryCode?.currencyPosition
                ? i?.job?.countryCode?.currency + i?.job?.salary
                : i?.job?.salary + i?.job?.countryCode?.currency
              : "";

            return {
              ...i,
              salaryWithCurrency,
            };
          });
          // console.log("Get Notifications...........", changedData)
          setNotifications(changedData);
        }
      });
  };

  const deleteNotification = (notificationId, doesNavigate, jobdetails) => {
    let NotificationData = {
      notificationId: notificationId,
      seen: true,
      delete: !doesNavigate,
    };
    // console.log("open linkedin", NotificationData);
    putAPICall(Url?.GetNotifications, NotificationData)
      .then((res) => {
        if (res.SUCCESS) {
          if (jobdetails?.jobId) {
            navigate(`/Main/jobDetails/${jobdetails?.jobId}`, { state: { notificationId } });
          } else {
            getNotifications();
            setAlert(`The notification is deleted successfully!`);
          }
        } else {
          setWarning(res.message);
        }
      });
  };

  return (
    <div className="NotificationPageWrapper">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: '0 20px 10px 20px '
        }}
      >
        <ComponentAndScreenConstant.Breadcrumbs currentPage={["Notification"]} />
      </div>
      {notifications.length == 0 ? (
        <div className="noNotificationMsg">
          <img src={nodata} className="nodataImage" />
          <p>There are currently no notifications available.</p>
        </div>
      ) : (
        notifications?.map((item, index) => {
          return (
            <div key={index}
              className="NotificationContainerWrapper"
            >
              <div className="NotificationContainer">
                <div className="NotificationCardWrapper">
                  <div className="NotificationCard">
                    <div className="NotificationCardUpperPart">
                      <p>{item?.notificationTitle}</p>{" "}
                      <p className="NotificationCardsalary">
                        {item?.salaryWithCurrency
                          ? item?.salaryWithCurrency + " LPA"
                          : "Not Disclosed"}
                      </p>
                    </div>
                    <div className="NotificationCardLowerPart">
                      <p
                        className="NotificationCardJobTitle"
                        onClick={() => {
                          deleteNotification(
                            item?.notificationId,
                            true,
                            item?.job
                          );
                        }}
                      >
                        {item?.job?.designation
                          ?.map((v) => v?.designationName)
                          .join(", ")}
                      </p>
                      <div className="NotificationCardDeatails">
                        <div className="NotificationCardDeatailsLeft">
                          <div className="NotificationCardskillWrapper">
                            <img
                              src={experienceLogo}
                              className="NotificationCardapplicantSkillLogo"
                            />
                            <p style={{ marginLeft: "10px" }}>
                              {item?.job?.experience?.experience}
                            </p>
                          </div>
                          <div className="NotificationCardskillWrapper">
                            <img
                              src={caenderLogo}
                              className="NotificationCardapplicantSkillLogo"
                            />
                            <p style={{ marginLeft: "10px" }}>
                              Active Till Date:{" "}
                              {item?.job?.tillDate
                                ? moment(item?.job?.tillDate).format(
                                  "DD-MMM-YYYY"
                                )
                                : "-"}
                            </p>
                          </div>
                          <div className="NotificationCardskillWrapper" style={{ marginBottom: window.screen.width < 1024 && "5px" }}>
                            <img
                              src={locationLogo}
                              className="NotificationCardapplicantSkillLogo"
                            />
                            <p style={{ marginLeft: "10px" }}>
                              {item?.job?.state
                                ?.map((v) => v?.stateName)
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                        <div className="NotificationCardDeatailsRight">
                          <img
                            onClick={() =>
                              deleteNotification(item?.notificationId, false)
                            }
                            src={deleteRoundLogo}
                            alt="Delete Icon"
                            className="NotificationCardDeleteIcon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Notification;
