import './SimilarJobs.css'
import { Url } from '../../EnvoirnmentSetup/APIs'
import React, { useEffect, useState } from 'react'
import { getAPICall } from '../../APIMethods/APIMethods'
import { useLocation, useNavigate } from 'react-router-dom'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'

const SimilarJobs = () => {
  const navigate = useNavigate()
  const locationData = useLocation()
  const screenName = `${locationData?.state?.screen ?? 'suggested'} Job`
  const [jobList, setJobList] = useState([])
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(["Similar Jobs", "Job Details", screenName]);

  useEffect(() => {
    let url = ''
    if (locationData?.state?.Flow == 'Recruiter_Jobs') {
      url = Url.GetJobsByRecruiterId.replace('{userId}', locationData?.state?.recruiterDetails?.userId)
      setCurrentPage([("Jobs posted by " + locationData?.state?.recruiterName ?? ''), "Job Details", screenName]);
    } else if (locationData?.state?.Flow == 'Company_Jobs') {
      url = Url.GetJobsByCompanyUid.replace('{companyUid}', locationData?.state?.companyUid) //locationData?.state?.companyName
      setCurrentPage([("Jobs posted by " + locationData?.state?.companyName ?? ''), "Job Details", screenName]);
    } else {
      url = Url.GetAllSimilarJobs.replace('{jobId}', locationData?.state?.jobId)
    }

    setLoader(true)
    getAPICall(url)
      .then((res) => {
        if (res.SUCCESS) {
          setLoader(false)
          let d = res?.DATA?.map((i) => {
            let stateStr = i?.state?.map((s, ind) => s.stateName).join(', ')
            let skillStr = i?.skill?.map((k, index) => k?.name).join(', ')
            return { ...i, stateString: stateStr, skillString: skillStr }
          })
          setJobList(d)
        }
      })
  }, [locationData?.state])

  return (
    <div className='similarJobsMainSection'>
      <div className="similarJobBreadCrumb">
        <ComponentAndScreenConstant.Breadcrumbs currentPage={currentPage} />
      </div>
      <div className='similarAllJobs'>
        <div className='similarJobsCards'>
          {jobList.length > 0 ? <>
            {jobList.map((item) => (
              <div className='seperateJobCardSimilar' key={item?.jobId} onClick={() => { navigate('/Main/jobDetails', { state: { jobId: item?.jobId, headerHide: true } }) }}>
                <ComponentAndScreenConstant.jobCard jobDetails={item} />
              </div>
            ))}</> : <div className='notFound'>
            <img src={require('../../assets/NotFound.png')} style={{ height: '30vh', width: '30vh', }} />
            No Data Found.
          </div>}
        </div>
      </div>
      <ComponentAndScreenConstant.LoaderModal setLoader={loader} />
    </div>
  )
}

export default SimilarJobs