export const protocol = 'http'

export const ApiProfile = {
    DevV1: `${protocol}://13.52.157.11:8080/quikhire-dev/api/v1/`,
    IntV1: `${protocol}://13.52.157.11:8080/quikhire-int/api/v1/`,
    UatV1: `${protocol}://13.52.157.11:8080/quikhire-uat/api/v1/`,
    ProdV1: `https://applicant.quikhire.ai/quikhire/api/v1/`,
}

export const WebProfile = {
    DevRecruiterV1: `${protocol}://13.52.157.11:8080/quikhire_dev_recruiter/`,
    IntRecruiterV1: `${protocol}://13.52.157.11:8080/quikhire_int_recruiter/`,
    UatRecruiterV1: `${protocol}://13.52.157.11:8080/quikhire_uat_recruiter/`,
    ProdRecruiterV1: `https://recruiter.quikhire.ai/`,

    DevLeaderV1: `${protocol}://13.52.157.11:8080/quikhire_dev_manager/`,
    IntLeaderV1: `${protocol}://13.52.157.11:8080/quikhire_int_manager/`,
    UatLeaderV1: `${protocol}://13.52.157.11:8080/quikhire_uat_manager/`,
    ProdLeaderV1: `https://manager.quikhire.ai/`,

    DevAdminV1: `${protocol}://13.52.157.11:8080/quikhire_dev_admin/`,
    IntAdminV1: `${protocol}://13.52.157.11:8080/quikhire_int_admin/`,
    UatAdminV1: `${protocol}://13.52.157.11:8080/quikhire_uat_admin/`,
    ProdAdminV1: `https://admin.quikhire.ai/`,

    DevApplicantV1: `${protocol}://13.52.157.11:8080/quikhire_dev_applicent/`,
    IntApplicantV1: `${protocol}://13.52.157.11:8080/quikhire_int_applicent/`,
    UatApplicantV1: `${protocol}://13.52.157.11:8080/quikhire_uat_applicent/`,
    ProdApplicantV1: `https://applicant.quikhire.ai/`,

}

export const StudentDeepLinking = {
    DevV1: `${protocol}://localhost:3000/quikhire_int_applicant/#/`,
    IntV1: `${protocol}://13.52.157.11:8080/quikhire_int_applicant/#/`,
    UatV1: `${protocol}://13.52.157.11:8080/quikhire_uat_applicant/#/`,
    ProdV1: `https://applicant.quikhire.ai/#/`,
}

export const ResumeBuilderAPIProfile = {
    DevV1: `${protocol}://localhost:8080/`,
    IntV1: `${protocol}://13.52.157.11:8080/resume-builder/api/v1/`,
    UatV1: `${protocol}://13.52.157.11:8080/resume-builder/api/v1/`,
    ProdV1: `https://api.shareresume.co/api/v1/`
}

export const ResumeBuilderWebProfile = {
    DevV1: `${protocol}://13.52.157.11:8080/resume_builder_int/#/`,
    IntV1: `${protocol}://13.52.157.11:8080/resume_builder_int/#/`,
    UatV1: `${protocol}://13.52.157.11:8080/resume_builder_uat/#/`,
    ProdV1: `https://www.shareresume.co/#/`,
}


export const getBaseURLTonavigatetoLogin = (profile, role) => {
    if (profile.toLocaleLowerCase().includes('dev')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.DevRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.DevLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.DevAdminV1
            return (dataURL);
        } else /* if (role == "ROLE_APPLICANT")  */ {
            let dataURL = WebProfile.DevApplicantV1
            return (dataURL);
        }
    }
    if (profile.toLocaleLowerCase().includes('int')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.IntRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.IntLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.IntAdminV1
            return (dataURL);
        } else /* if (role == "ROLE_APPLICANT") */ {
            let dataURL = WebProfile.IntApplicantV1
            return (dataURL);
        }
    }

    if (profile.toLocaleLowerCase().includes('uat')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.UatRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.UatLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.UatAdminV1
            return (dataURL);
        } else /* if (role == "ROLE_APPLICANT")  */ {
            let dataURL = WebProfile.UatApplicantV1
            return (dataURL);
        }
    }
    if (profile.toLocaleLowerCase().includes('prod')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.ProdRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.ProdLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.ProdAdminV1
            return (dataURL);
        } else /* if (role == "ROLE_APPLICANT") */ {
            let dataURL = WebProfile.ProdApplicantV1
            return (dataURL);
        }
    }
}
