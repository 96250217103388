import './Reports.css'
import React from 'react'
import moment from 'moment'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { useSelectedCardContext } from '../../Context/Context'
import { ApiCall } from '../../APIMethods/APIMethods'
import { Url } from '../../EnvoirnmentSetup/APIs'
import { ModuleCards } from '../../constants/ModuleCards'
import { ExcelDownload } from '../../constants/constantFunctions'

export default function Reports() {
    const { selectedCard, setSelectedCard, setIsLoading, setWarning, setAlert } = useSelectedCardContext()

    const [StartDate, setStartDate] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
    const [EndDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'))
    const [Reports, setReports] = React.useState()

    React.useEffect(() => {
        setSelectedCard(ModuleCards?.reports)
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (!!user) {
            setIsLoading(true)
            ApiCall(Url.GetUserReports.replace('{userId}', user?.userId), 'GET', true)
                .then((res) => {
                    setIsLoading(false)
                    if (res.SUCCESS) {
                        setReports(res.DATA)
                    } else {
                        setReports(null)
                    }
                })
        }
    }, [])

    const GetReports = () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        // if (!!!StartDate) {
        //     setWarning('Select from date to filter reports.')
        //     return
        // } else 
        // if (!!!EndDate) {
        //     setWarning('Select to date to filter reports.')
        //     return
        // } else 
        if ((new Date(EndDate) - new Date(StartDate)) < 0) {
            setWarning('To date should be grater than From date to filter reports.')
            return
        }
        if (!!user) {
            setIsLoading(true)
            ApiCall(Url.GetUserReportsByDate.replace('{userId}', user?.userId).replace('{fromDate}', moment(StartDate).format('YYYY-MM-DD')).replace('{toDate}', moment(EndDate).format('YYYY-MM-DD')), 'GET', true)
                .then((res) => {
                    setIsLoading(false);
                    if (res.SUCCESS) {
                        setReports(res.DATA)
                    } else {
                        setReports(null)
                    }
                })
        }
    }

    const DownloadReports = (key, name) => {       
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (!!user) {
            let url = Url.DownloadReports
            if (!!!StartDate && !!!EndDate && (new Date(EndDate) - new Date(StartDate)) < 0) {
                url = Url.DownloadReportsByDate.replace('{fromDate}', moment(StartDate).format('YYYY-MM-DD')).replace('{toDate}', moment(EndDate).format('YYYY-MM-DD'))
            }
            setIsLoading(true)
            ExcelDownload(url.replace('{userId}', user?.userId).replace('{key}', key), /* user.fullName + '_' + */ name)
                .then((res) => {
                    setIsLoading(false);
                    if (res) {
                        setAlert('Excel Downloaded successfully.')
                    } else {
                        setWarning('something went wrong please try again later.')
                    }
                })
        }
    }
    const formatJobCount = (count) => {
        if (count === null || count === undefined) return '00';
        return count < 10 ? `0${count}` : `${count}`;
    };

    return (
        <div className='reportMainContainer'>
            <div className='breadCrumbDiv'>
                <ComponentAndScreenConstant.Breadcrumbs currentPage={["My Reports"]} />
                {/* <div className="sortMain"> */}
                <div className='selectorClass'>
                    <ComponentAndScreenConstant.DatePickerField
                        labelTxt={'From Date :'}
                        selectedValue={StartDate}
                        placeholderText={'DD-MM-YYYY'}
                        maxDate={new Date()}
                        inputWrapperClassName={'datePickerInput'}
                        onChange={(e) => { setStartDate(e); setEndDate() }}
                    />
                    <ComponentAndScreenConstant.DatePickerField
                        labelTxt={'To Date :'}
                        selectedValue={EndDate}
                        placeholderText={'DD-MM-YYYY'}
                        onChange={(e) => setEndDate(e)}
                        minDate={new Date(StartDate)}
                        maxDate={new Date()}
                        inputWrapperClassName={'datePickerInput'}
                    />
                    <button className='filterDetails' onClick={GetReports}>
                        Submit
                    </button>
                </div>
                {/* <button className='filterDetails'>
                    Download Excel
                </button> */}
                {/* </div> */}
            </div>
            {/* <div className='tableDiv'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Reports</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td className='second-child'>Applied Jobs</td>
                            <td>
                                <span
                                    className={`reportCount ${Reports?.appliedJobs ? 'activeReport' : ''}`}
                                    onClick={() => Reports?.appliedJobs ? DownloadReports('appliedJobs', 'Applied_Jobs') : null}
                                    title='Applied Jobs Reports Excel'>
                                    {formatJobCount(Reports?.appliedJobs)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td className='second-child'>Viewed Jobs</td>
                            <td>
                                <span
                                    className={`reportCount ${Reports?.viewedJobs ? 'activeReport' : ''}`}
                                    onClick={() => Reports?.viewedJobs ? DownloadReports('viewedJobs', 'Viewed_Jobs') : null}
                                    title='Viewed Jobs Reports Excel'>
                                    {formatJobCount(Reports?.viewedJobs)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td className='second-child'>Shortlisted for Interview</td>
                            <td>
                                <span
                                    className={`reportCount ${Reports?.interviewShortlisted ? 'activeReport' : ''}`}
                                    onClick={() => Reports?.interviewShortlisted ? DownloadReports('interviewShortlisted', 'Shortlisted_for_Interview') : null}
                                    title='Shortlisted for Interview Reports Excel'>
                                    {formatJobCount(Reports?.interviewShortlisted)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td className='second-child'>Interview Scheduled</td>
                            <td>
                                <span
                                    className={`reportCount ${Reports?.interviewScheduled ? 'activeReport' : ''}`}
                                    onClick={() => Reports?.interviewScheduled ? DownloadReports('interviewScheduled', 'Interview_Scheduled_Jobs') : null}
                                    title='Interview Scheduled Jobs Reports Excel'>
                                    {formatJobCount(Reports?.interviewScheduled)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td className='second-child'>Shortlisted Jobs</td>
                            <td>
                                <span
                                    className={`reportCount ${Reports?.shortlistedJobs ? 'activeReport' : ''}`}
                                    onClick={() => Reports?.shortlistedJobs ? DownloadReports('shortlistedJobs', 'Shortlisted_Jobs') : null}
                                    title='Shortlisted Jobs Reports Excel'>
                                    {formatJobCount(Reports?.shortlistedJobs)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td className='second-child'>Rejected Jobs</td>
                            <td>
                                <span
                                    className={`reportCount ${Reports?.rejectedJobs ? 'activeReport' : ''}`}
                                    onClick={() => Reports?.rejectedJobs ? DownloadReports('rejectedJobs', 'Rejected_Jobs') : null}
                                    title='Rejected Jobs Reports Excel'>
                                    {formatJobCount(Reports?.rejectedJobs)}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            <div className='reportDataContainer'>
                <div className='reportCard'>
                    <button
                        className={`reportCount ${Reports?.appliedJobs ? 'activeReport' : 'noSelect'}`}
                        onClick={() => Reports?.appliedJobs ? DownloadReports('appliedJobs', 'Applied_Jobs') : null}
                        title='Applied Jobs Reports Excel'>
                        {formatJobCount(Reports?.appliedJobs)}
                    </button>
                    <p>Applied Jobs</p>
                </div>
                <div className='reportCard'>
                    <button
                        className={`reportCount ${Reports?.viewedJobs ? 'activeReport' : 'noSelect'}`}
                        onClick={() => Reports?.viewedJobs ? DownloadReports('viewedJobs', 'Viewed_Jobs') : null}
                        title='Viewed Jobs Reports Excel'>
                        {formatJobCount(Reports?.viewedJobs)}
                    </button>
                    <p>Viewed Jobs</p>
                </div>
                <div className='reportCard'>
                    <button
                        className={`reportCount ${Reports?.interviewShortlisted ? 'activeReport' : 'noSelect'}`}
                        onClick={() => Reports?.interviewShortlisted ? DownloadReports('interviewShortlisted', 'Shortlisted_for_Interview') : null}
                        title='Shortlisted for Interview Reports Excel'>
                        {formatJobCount(Reports?.interviewShortlisted)}
                    </button>
                    <p>Shortlisted for Interview</p>
                </div>
                <div className='reportCard'>
                    <button
                        className={`reportCount ${Reports?.interviewScheduled ? 'activeReport' : 'noSelect'}`}
                        onClick={() => Reports?.interviewScheduled ? DownloadReports('interviewScheduled', 'Interview_Scheduled_Jobs') : null}
                        title='Interview Scheduled Jobs Reports Excel'>
                        {formatJobCount(Reports?.interviewScheduled)}
                    </button>
                    <p>Interview Scheduled</p>
                </div>
                <div className='reportCard'>
                    <button
                        className={`reportCount ${Reports?.shortlistedJobs ? 'activeReport' : 'noSelect'}`}
                        onClick={() => Reports?.shortlistedJobs ? DownloadReports('shortlistedJobs', 'Shortlisted_Jobs') : null}
                        title='Shortlisted Jobs Reports Excel'>
                        {formatJobCount(Reports?.shortlistedJobs)}
                    </button>
                    <p>Shortlisted Jobs</p>
                </div>
                <div className='reportCard'>
                    <button
                        className={`reportCount ${Reports?.rejectedJobs ? 'activeReport' : 'noSelect'}`}
                        onClick={() => Reports?.rejectedJobs ? DownloadReports('rejectedJobs', 'Rejected_Jobs') : null}
                        title='Rejected Jobs Reports Excel'>
                        {formatJobCount(Reports?.rejectedJobs)}
                    </button>
                    <p>Rejected Jobs</p>
                </div>
            </div>
        </div>
    )
}
