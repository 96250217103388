import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelectedCardContext } from '../../Context/Context';
import { ModuleCards } from '../../constants/ModuleCards';

import QuikHire from '../../assets/quikHireLogo.png';
import reportsIcon from '../../assets/reportsIcon.svg';
import myResumeIcon from '../../assets/myResumeIcon.svg';
import appliedGrayIcon from '../../assets/appliedGrayIcon.svg';
import reportsIconWhite from '../../assets/reportsIconWhite.svg';
import appliedActiveIcon from '../../assets/appliedActiveIcon.svg';
import myResumeIconWhite from '../../assets/myResumeIconWhite.svg';
import suggestedGrayIcon from '../../assets/suggestedGrayIcon.svg';
import dashboardGrayIcon from '../../assets/dashboardGrayIcon.svg';
import suggestedActiveIcon from '../../assets/suggestedActiveIcon.svg';
import dashboardActiveIcon from '../../assets/dashboardActiveIcon.svg';

const Sidebar = ({ onClick }) => {
    const navigate = useNavigate()
    const location = useLocation();
    let path = location.pathname;
    const { selectedCard, setSelectedCard } = useSelectedCardContext()

    const [userData, setUserData] = React.useState()

    useEffect(() => {
        setUserData(JSON.parse(sessionStorage.getItem("user")))
        const token = sessionStorage.getItem('token');
        let url = window.location.href;
        if (!!!token) {
            if (url.toLowerCase().indexOf('/main/jobdetails/') > -1) {
                let urlData = url.split('/');
                // console.log('......', urlData[urlData.length - 1]);
                sessionStorage.setItem('jobId', urlData[urlData.length - 1])
            }
            navigate('/login')
        }
    }, [selectedCard])

    useEffect(() => {
        if (path.includes("/latestJob")) {
            setSelectedCard(1);
        } else if (path.includes("/suggested") || path.includes("/suggestedPref") || path.includes("/notification")) {
            setSelectedCard(2);
        } else if (path.includes("/applied")) {
            setSelectedCard(3);
        } else if (path.includes("/myResume")) {
            setSelectedCard(4);
        } else if (path.includes("/reports")) {
            setSelectedCard(5);
        } else {
            // setSelectedCard(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);


    return (
        <>
            <div className='quikHireLogoDiv'>
                <NavLink
                    to="/Main/latestJob"
                    state={{ screen: 'latest' }}
                    onClick={() => {
                        onClick()
                        setSelectedCard(ModuleCards?.LatestJobs)
                    }}>
                    <img src={QuikHire} alt="QuikHire Logo" className='quikHireLogo' />
                </NavLink>
            </div>
            <ul className='linkingMenu'>
                <li>
                    <NavLink
                        to="/Main/latestJob"
                        state={{ screen: 'latest' }}
                        onClick={() => {
                            onClick()
                            setSelectedCard(ModuleCards?.LatestJobs)
                        }}
                        className={selectedCard == ModuleCards?.LatestJobs ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.LatestJobs ? dashboardActiveIcon : dashboardGrayIcon} alt="Dashboard Icon" /> Latest Jobs
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={!!!userData?.preferenceSet ? "/suggestedPref" : '/Main/suggested'}
                        state={{ screen: 'suggested' }}
                        onClick={() => {
                            onClick()
                            setSelectedCard(ModuleCards.SuggestedJobs)
                        }}
                        className={selectedCard == ModuleCards?.SuggestedJobs ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.SuggestedJobs ? suggestedActiveIcon : suggestedGrayIcon} alt="Suggested Icon" /> Suggested Jobs
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/Main/applied"
                        state={{ screen: 'applied' }}
                        onClick={() => {
                            onClick()
                            setSelectedCard(ModuleCards.AppliedJobs)
                        }}
                        className={selectedCard == ModuleCards?.AppliedJobs ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.AppliedJobs ? appliedActiveIcon : appliedGrayIcon} alt="Applied Icon" /> Applied Jobs
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/Main/myResume"
                        state={{ headerHide: false }}
                        onClick={() => {
                            onClick()
                            setSelectedCard(ModuleCards.myResume)
                        }}
                        className={selectedCard == ModuleCards?.myResume ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.myResume ? myResumeIconWhite : myResumeIcon} alt="myResume Icon" /> My Resume
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/Main/reports" state={{ headerHide: false }} onClick={() => setSelectedCard(ModuleCards.reports)} className={selectedCard == ModuleCards?.reports ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.reports ? reportsIconWhite : reportsIcon} alt="reports Icon" /> My Reports
                            </>
                        )}
                    </NavLink>
                </li>
            </ul>
        </>
    )
}

export default Sidebar