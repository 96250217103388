import React, { useEffect, useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import calenderIcon from '../../assets/GroupCalender.svg'
import './DatePickerField.css'
import moment from 'moment';

const DatePickerField = ({
    labelTxt,
    inputWrapperStyle,
    inputWrapperClassName,
    errMsg,
    inputClass,
    onChange,
    minDate,
    maxDate,
    inputId,
    selectedValue, dateFormat,
    showYearPicker,
    disabledData,
    showMonthYearPicker,
    placeholderText,
    isRequired = false
}) => {
    const datePickerRef = useRef(null);

    const handleIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);
        }
    };

    return (
        <div className={`${inputWrapperClassName} custom-datepicker-container`} style={{ display: "flex", flexDirection: "column", ...inputWrapperStyle }}>
            {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
            <div className='datePickerMainDiv'>
                <ReactDatePicker
                    ref={datePickerRef}
                    onChange={onChange}
                    menuPlacement="auto"
                    disabled={disabledData}
                    dateFormat={'dd-MM-yyyy'}
                    showYearPicker={showYearPicker}
                    showMonthYearPicker={showMonthYearPicker}
                    placeholderText={placeholderText}
                    className={`${inputClass} custom-datepicker`}
                    calendarClassName="custom-datepicker-calendar"
                    // onKeyDown={(e) => e.preventDefault()}
                    minDate={!!minDate ? new Date(moment(minDate).format('YYYY-MM-DD')) : undefined}
                    maxDate={!!maxDate ? new Date(moment(maxDate).format('YYYY-MM-DD')) : undefined}
                    selected={!!selectedValue ? new Date(moment(selectedValue).format('YYYY-MM-DD')) : undefined}
                    withPortal={window.screen.width < 433 ? true : false}
                />
                <img style={{ opacity: disabledData && "0.5" }} src={calenderIcon} alt="" onClick={() => { handleIconClick() }} />
            </div>
            {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
        </div>
    )
}

export default DatePickerField