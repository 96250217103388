import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { useSelectedCardContext } from '../../Context/Context'
import jobPreferenceIcon from "../../assets/jobPreference.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import latestJobIcon from '../../assets/latestJobIcon.svg'
import { getAPICall, postAPICall } from '../../APIMethods/APIMethods'
import searchIcon from '../../assets/searchIcon.svg'
import React, { useEffect, useState } from 'react'
import { Url } from '../../EnvoirnmentSetup/APIs'
import Modal from "react-modal";
import './Dashboard.css'

const Dashboard = () => {
    const navigate = useNavigate()
    const locationData = useLocation()
    const { selectedCard, setSelectedCard, setIsLoading } = useSelectedCardContext()
    const screenName = locationData?.state?.screen ?? 'latest'

    const [jobList, setJobList] = useState([])
    const [jobList2, setJobList2] = useState([])
    const [searchVal, setSearchVal] = useState('')
    const [preferencesModal, setPreferencesModal] = useState(false)
    const [preferenceDetails, setPreferenceDetails] = useState({
        keywords: [],
        location: [],
        experience: {},
        workMode: [],
        jobType: [],
        preferenceSet: false
    })

    useEffect(() => {
        let url = ''
        if (screenName == 'applied') {
            setSelectedCard(3)
            url = Url.GetAllInterestedJob
        } else if (screenName == 'suggested') {
            setSelectedCard(2)
            url = Url.GetAllFilterdJob
        } else {
            setSelectedCard(1)
            url = Url.getAllJobs
        }

        setIsLoading(true)
        getAPICall(url)
            .then((res) => {
                document.getElementsByClassName('sortSelect')[0].selectedIndex = 0;
                setIsLoading(false)
                let d = res?.DATA?.map((i) => {
                    if (screenName == 'applied') {
                        let stateStr = i?.job?.state?.map((s, ind) => s.stateName).join(', ') ?? ''
                        let skillStr = i?.job?.skill?.map((k, index) => k?.name).join(', ') ?? ''
                        return { ...i, job: { ...i.job, stateString: stateStr, skillString: skillStr } }
                    } else {
                        let stateStr = i?.state?.map((s, ind) => s.stateName).join(', ') ?? ''
                        let skillStr = i?.skill?.map((k, index) => k?.name).join(', ') ?? ''
                        return { ...i, stateString: stateStr, skillString: skillStr }
                    }
                }) ?? []
                setJobList(d)
                setJobList2(d)
            })

        getAPICall(Url?.getuser)
            .then((res) => {
                if (res?.SUCCESS && res?.DATA?.preferenceSet) {
                    setPreferenceDetails({
                        keywords: res?.DATA?.skill,
                        location: res?.DATA?.state,
                        experience: res?.DATA?.experience,
                        workMode: res?.DATA?.workMode,
                        jobType: res?.DATA?.jobType,
                        preferenceSet: res?.DATA?.preferenceSet
                    })
                }
            })
    }, [locationData?.state])
    // console.log('preferenceDetails', preferenceDetails);

    useEffect(() => {
        try {
            if (jobList2?.length > 0) {
                // let newValue = [...jobList2.filter((v) => JSON.stringify(v)?.toLowerCase()?.indexOf(searchVal?.toLowerCase()) > -1)]
                // setJobList([...newValue])
                setJobList(jobList2?.filter((item) => {
                    if (screenName == 'applied') {
                        return (
                            (item?.job?.designation[0]?.designationName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.company?.companyName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.stateString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.skillString?.toLowerCase())?.includes(searchVal?.toLowerCase())
                        )
                    } else {
                        return (
                            (item?.designation[0]?.designationName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.company?.companyName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.stateString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.skillString?.toLowerCase())?.includes(searchVal?.toLowerCase())
                        )
                    }
                }))
            }
        } catch (e) {
            console.log(e);
        }
    }, [searchVal, locationData?.state])

    const getPreferenceDetails = () => {
        // console.log("preferenceDetail", preferenceDetails);

        if (preferenceDetails?.preferenceSet) {
            setPreferencesModal(true)
        } else {
            navigate('/suggestedPref')
        }
    }

    const handleEditPreference = () => {
        setPreferencesModal(false)
        navigate('/suggestedPref', { state: { editFlow: true } })
    }

    const handleSortChange = (e) => {
        const selectedValue = e.target.value;
        // Implement sorting logic based on selectedValue
        let sortedList = [...jobList]; // Create a copy of jobList to avoid mutating state directly
        if (selectedValue === '1') {
            // Sort by Posted Date
            sortedList?.sort((a, b) => new Date(b.postedDate ?? b.job.postedDate) - new Date(a.postedDate ?? a.job.postedDate));
        } else if (selectedValue === '2') {
            // Sort by Active Date
            sortedList?.sort((a, b) => new Date(a.tillDate ?? a.job.tillDate) - new Date(b.tillDate ?? b.job.tillDate));
        } else /* if (selectedValue === '3')  */ {
            // Sort by Experience
            sortedList?.sort((a, b) => (a?.experience?.experienceId ?? a?.job?.experience?.experienceId) - (b?.experience?.experienceId ?? b?.job?.experience?.experienceId));
        }
        // Update state with sorted list
        setJobList(sortedList);
    };

    return (
        <div className='dashboardMainDiv'>
            <div className='headingRowDashboardDiv'>
                <div className='latestJobMainDiv'>
                    <div className='latestJobIconDiv'>
                        <img className='latestJobIcon' src={latestJobIcon} alt="" />
                    </div>
                    <div className='latestJobTextDiv'>
                        <span>{screenName} Jobs</span>
                    </div>
                </div>
                <div className='searchAndSortDiv'>
                    {screenName == 'suggested' && <div className='search'>
                        <button type='button' className='prefernceBtn' onClick={getPreferenceDetails}><img src={jobPreferenceIcon} alt='jobPreferenceIcon' style={{ height: '20px', margin: '2px 0 2px 5px' }} /> Job Preferences</button>
                    </div>}
                    <div className='search'>
                        <img className='searchIcon' src={searchIcon} alt='' />
                        <input className='latestJobSearch' type="text" placeholder='Search Jobs' value={searchVal} onChange={(e) => setSearchVal(e?.target?.value)} />
                    </div>
                    <div className='sort'>
                        <select className="sortSelect" name="" id="" onChange={(e) => { handleSortChange(e) }}>
                            {/* <option value="">Select</option> */}
                            <option value="1">Sort by Posted Date</option>
                            <option value="2">Sort by Active Date</option>
                            <option value="3">Sort by Experience</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='jobCards'>
                {jobList?.length > 0 ? <>
                    {jobList?.map((item) => (
                        <div
                            className='latestSeperateJobCard'
                            key={item?.jobId}
                            onClick={() => {
                                // console.log(screenName == 'applied' ? item?.job?.jobId : item?.jobId);
                                navigate('/Main/jobDetails', { state: { jobId: screenName == 'applied' ? item?.job?.jobId : item?.jobId, headerHide: true, screen: screenName } })
                            }}>
                            <ComponentAndScreenConstant.jobCard
                                jobDetails={screenName == 'applied' ? item?.job : item}
                                applied={screenName == 'applied'}
                                applicationStatus={screenName == 'applied' ? item : undefined}
                            />
                        </div>
                    ))}</> : <div className='notFound'>
                    <img src={require('../../assets/NotFound.png')} style={{ height: '30vh', width: '30vh', }} />
                    No Data Found.
                </div>}
            </div>
            <Modal
                isOpen={preferencesModal}
                onRequestClose={() => { setPreferencesModal(false) }}
                ariaHideApp={false}
                className={`modalStyle`}
                overlayClassName={'modalOverlayStyle AlertModal'}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className='selectedPreferenceModal'>
                    <span className='closePreferenceModal' onClick={() => setPreferencesModal(false)}>X</span>
                    <h3 className='jobPreHeader'>Your Job Preferences</h3>
                    <div className='jobPreKeywordsDiv'>
                        <span>Keywords</span>
                        <span>
                            {preferenceDetails?.keywords?.map((item, i) => {
                                return (
                                    <span>{item?.name}{i == preferenceDetails?.keywords?.length - 1 ? "." : ", "}</span>
                                )
                            })}
                        </span>
                    </div>
                    <div className='jobPreKeywordsDiv'>
                        <span>Job Location</span>
                        <span>
                            {preferenceDetails?.location?.map((item, i) => {
                                return (
                                    <span>{item?.stateName}{i == preferenceDetails?.location?.length - 1 ? "." : ", "}</span>
                                )
                            })}
                        </span>
                    </div>
                    <div className='jobPreCardsDiv'>
                        <div className='jobPreCard'>
                            <div className='jobPreInnerCard'>
                                <span>Experience</span>
                                <span>{preferenceDetails?.experience?.experience}</span>
                            </div>
                        </div>
                        {preferenceDetails?.workMode?.length > 0 ?
                            <div className='jobPreCard'>
                                <div className='jobPreInnerCard'>
                                    <span>Work Mode</span>
                                    <span>
                                        {preferenceDetails?.workMode?.map((item, i) => {
                                            return (
                                                <span>{item?.workMode}{i == preferenceDetails?.workMode?.length - 1 ? "." : ", "}</span>
                                            )
                                        })}
                                    </span>
                                </div>
                            </div> : <></>

                        }
                        {preferenceDetails?.jobType?.length > 0 ?

                            <div className='jobPreCard'>
                                <div className='jobPreInnerCard'>
                                    <span>Job Type</span>
                                    <span>
                                        {preferenceDetails?.jobType?.map((item, i) => {
                                            return (
                                                <span>{item?.jobType}{i == preferenceDetails?.jobType?.length - 1 ? "." : ", "}</span>
                                            )
                                        })}
                                    </span>
                                </div>
                            </div> : <></>
                        }
                    </div>
                    <div className='jobPreModalBtns'>
                        <button id='cnclBtnPreModal' onClick={() => setPreferencesModal(false)}>Cancel</button>
                        <button id='editBtnPreModal' onClick={handleEditPreference}>Edit</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Dashboard