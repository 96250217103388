import React from 'react'
import './RecruiterInfo.css'
import { useLocation } from 'react-router-dom'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'

function RecruiterInfo() {
    const locationData = useLocation()
    const { job } = locationData?.state

    const maskNumber = (num) => {
        // Convert number to string in case it's a number
        const strNum = String(num);
        // Mask from the 4th to 8th places (index 3 to 7)
        return strNum.slice(0, 3) + '*****' + strNum.slice(8);
    };
    const maskEmail = (email) => {
        const [username, domain] = email.split('@');
        const maskedUsername = username.replace(/./g, '*'); // Mask all characters in the username
        return `${maskedUsername}@${domain}`;
    };

    return (
        <div className='recruiterInfoContainer'>
            <div className='breadCrumbDiv'>
                <ComponentAndScreenConstant.Breadcrumbs currentPage={["Recruiter Info", "Job Details", `${locationData?.state?.screen ?? ''} Job`]} />
            </div>
            <div className='recruiterInfoDiv'>
                <div className='recruiterInfoLeftDiv'>
                    <img src={require('../../assets/recruiter.png')} />
                    <h1>{job?.jobPoster?.fullName}</h1>
                    <p>{job?.company?.companyName}</p>
                </div>
                <div className='recruiterInfoRightDiv'>
                    <div className='recruiterBasicInfo'>
                        <label>Mobile No :</label>
                        <p>+{job?.jobPoster?.countryCode?.countryCode + ' ' +  maskNumber(job?.jobPoster?.mobileNumber)}</p>
                    </div>
                    <div className='recruiterBasicInfo'>
                        <label>Email Id :</label>
                        <p>{maskEmail(job?.jobPoster?.userName)}</p>
                    </div>
                    {job?.jobPoster?.linkedinProfile && <div className='recruiterBasicInfo'>
                        <label>LinkedIn Profile :</label>
                        <p style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                window.open(job?.jobPoster?.linkedinProfile, '_blank')
                            }}>{job?.jobPoster?.linkedinProfile}</p>
                    </div>}

                </div>
            </div>
        </div>
    )
}

export default RecruiterInfo