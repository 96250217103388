import { ProfileConstant, RoleConstant } from "./ProfileConstants";
import { ApiProfile, ResumeBuilderAPIProfile, ResumeBuilderWebProfile, StudentDeepLinking, getWebProfileForDeepLinking } from "./profiles";

export const PortalUserRole = RoleConstant.ROLE_APPLICANT
export const LoginAutonavigateEnv = ProfileConstant.ProdApplicantV1;

const ResumeURL = ResumeBuilderAPIProfile.ProdV1
export const ResumeWebURL = ResumeBuilderWebProfile.ProdV1
//deeplink redirect
export const DynamicLink = StudentDeepLinking?.ProdV1

const baseURL = ApiProfile.ProdV1

export const Url = {
  CountryCodes: baseURL + "countrycodes",
  registerUser: baseURL + 'user/sign-up',
  SendOtp: baseURL + "sms/otp",
  UserLogin: baseURL + "login",
  getuser: baseURL + "user",
  otpVerify: baseURL + 'sms/otp/varify',
  mobileNoVerification: baseURL + 'public/user/countrycode/{countrycodeId}/username/{mobile_number}/iam/' + PortalUserRole,
  forgotPwd: baseURL + 'user/forgot-password',
  getAllJobs: baseURL + 'job',
  getAllKeywords: baseURL + 'skill',
  GetExperienceList: baseURL + 'public/experience',
  GetJobType: baseURL + 'job/job-type',
  GetWorkMode: baseURL + 'job/work-mode',
  getAllLocation: baseURL + 'state',
  submitPrefernece: baseURL + 'user/preference',
  GetAllFilterdJob: baseURL + 'user/job/filter',
  getUserPrefernces: baseURL + 'user/preference',
  getJobDetailsByJobId: baseURL + 'job/{id}',
  GetAllSimilarJobs: baseURL + 'job/{jobId}/similar',
  GetJobsByCompanyUid: baseURL + 'company/{companyUid}/jobs',
  postApplyJob: baseURL + 'user/job',
  GetAllInterestedJob: baseURL + 'user/job/intrested',
  getJobDetailsByJobIDApplicant: baseURL + 'user/job/{jobID}',
  changePassword: baseURL + 'user/update-password',
  GetNotifications: baseURL + "notification",
  getNotificationCount: baseURL + "notification/count",
  DeleteRejectedApplications: baseURL + 'user/job/status/{userJobId}',
  GetJobsByRecruiterId: baseURL + 'job/user/{userId}',
  SendFeedBack: baseURL + 'feedback',
  GetPostApplicationStatus: baseURL + 'job/{jobId}/applicant',
  GetUserReports: baseURL + 'user/job/reports/{userId}',
  GetUserReportsByDate: baseURL + 'user/job/reports/{userId}/from/{fromDate}/to/{toDate}',
  GetResume: baseURL + 'user/resume/{mobile}/country/{countryCodeId}',

  DownloadReports: baseURL + 'user/applicant/excel/{key}/{userId}',
  DownloadReportsByDate: baseURL + 'user/applicant/excel/{key}/from/{fromDate}/to/{toDate}/{userId}',
};

export const ResumeApiUrls = {
  GetResume: ResumeURL + 'user/resume/{mobile}/country/{countryCodeId}',
  GetResumeByUid: ResumeURL + 'user/resume/{userUid}',
} 