import React, { useState } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import './ApplicantDashboard.css';
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant';
import { Routes, Route, useLocation } from 'react-router-dom';
const ApplicantDashboard = () => {
    const locationData = useLocation()
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <div className='applicantDashboardSection'>
            <div className='applicantDashboardMainDiv'>
                <div aria-label="Toggle Sidebar Container" id="default-sidebar" className={`applicantDashboardSidebar sidebar ${isSidebarOpen ? 'visible' : 'hidden'}`}>
                    <div className="closeBtnDiv">
                        <button onClick={closeSidebar} id="close" className="close-btn">X</button>
                    </div>
                    <ComponentAndScreenConstant.Sidebar onClick={closeSidebar} />
                    <span className='allReserverd' style={{ textAlign: 'center' }}>© {new Date().getFullYear()} quikhire.ai <br />All rights reserved.</span>
                    <div className='footerLinks'>
                        <button onClick={() => { window.open('https://quikhire.ai/terms-of-use.php', '_blank', 'noopener,noreferrer') }}>Terms & Conditions</button>
                        <button onClick={() => { window.open('https://quikhire.ai/privacy-policy.php', '_blank', 'noopener,noreferrer') }}>Privacy Policy</button>
                    </div>
                </div>
                <div className='applicantDashboardContentDiv'>
                    <button onClick={toggleSidebar} id="toggle" className="e-btn toggleBtn" style={{ zIndex: 9 }} aria-label="Toggle Sidebar">
                        <RxHamburgerMenu />
                    </button>
                    <ComponentAndScreenConstant.Header />
                    {locationData?.state?.headerHide || window.location.href.toLowerCase().includes('jobdetails') ? <></> : <>
                        <ComponentAndScreenConstant.HeroSection />
                    </>}
                    <Routes>
                        <Route path='/latestJob' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/suggested' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/applied' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/jobDetails' element={<ComponentAndScreenConstant.JobDetails />} />
                        <Route path='/jobStatus' element={<ComponentAndScreenConstant.JobDetails />} />
                        <Route path='/jobDetails/:jobId' element={<ComponentAndScreenConstant.JobDetails />} />
                        <Route path='/userProfile' element={<ComponentAndScreenConstant.UserProfile />} />
                        <Route path='/SimilarJobs' element={<ComponentAndScreenConstant.SimilarJobs />} />
                        <Route path='/companyPostedJob' element={<ComponentAndScreenConstant.SimilarJobs />} />
                        <Route path='/recruiterListedJobs' element={<ComponentAndScreenConstant.SimilarJobs />} />
                        <Route path='/recruiterInfo' element={<ComponentAndScreenConstant.RecruiterInfo />} />
                        <Route path='/notification' element={<ComponentAndScreenConstant.Notification />} />
                        <Route path='/myResume' element={<ComponentAndScreenConstant.MyResume />} />
                        <Route path='/reports' element={<ComponentAndScreenConstant.Reports />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default ApplicantDashboard;
