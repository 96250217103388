import React, { useEffect, useState } from 'react'
import './SuggestedJobsPref.css'
import quikHireLogo from '../../assets/quikHireLogo.png'
import preferenceImage from '../../assets/preferenceImage.svg'
import quikWhiteLogo from '../../assets/quikWhiteLogo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { getAPICall, patchAPICall, postAPICall, putAPICall } from '../../APIMethods/APIMethods'
import { useSelectedCardContext } from '../../Context/Context'
import { Url } from '../../EnvoirnmentSetup/APIs'

const SuggestedJobsPref = () => {
    const propsData = useLocation()
    const navigate = useNavigate()
    const { selectedCard, setSelectedCard, setIsLoading, setWarning, setAlert } = useSelectedCardContext()

    const [allKeyWords, setAllKeyWords] = useState([])
    const [allExperience, setAllExperience] = useState([])
    const [workMode, setWorkMode] = useState([])
    const [jobType, setJobType] = useState([])
    const [allLocation, setAllLocation] = useState([])
    const [selectedEntries, setSelectedEntries] = useState({
        keyWordEntries: [],
        locationEntries: [],
        experienceEntry: {},
        workModeEntries: [],
        jobTypeEntries: []
    })

    useEffect(() => {
        getAPICall(Url.getAllKeywords)
            .then((res) => {
                const newArray = res.DATA.map((item) => ({
                    value: item.skillId,
                    label: item.name,
                }))
                setAllKeyWords(newArray)
            })
        getAPICall(Url.GetExperienceList)
            .then((res) => {
                const newArray = res.DATA.map((item) => ({
                    value: item.experienceId,
                    label: item.experience,
                }))
                setAllExperience(newArray)
            })
        getAPICall(Url.GetJobType)
            .then((res) => {
                const newArray = res.DATA.map((item) => ({
                    value: item.jobTypeId,
                    label: item.jobType,
                }))
                setJobType(newArray)
            })
        getAPICall(Url.GetWorkMode)
            .then((res) => {
                const newArray = res.DATA.map((item) => ({
                    value: item.workModeId,
                    label: item.workMode,
                }))
                setWorkMode(newArray)
            })
        getAPICall(Url.getAllLocation)
            .then((res) => {
                const newArray = res.DATA.map((item) => ({
                    value: item.stateId,
                    label: item.stateName,
                }))
                setAllLocation(newArray)
            })
        if (propsData?.state?.editFlow) {
            setIsLoading(true)
            getAPICall(Url?.getuser)
                .then((res) => {
                    // console.log('>>>>>>>>>>>', res)
                    let selectedKeywords = res?.DATA?.skill?.map((item) => {
                        return {
                            value: item?.skillId,
                            label: item?.name
                        }
                    })
                    let selectedExperience = {
                        value: res?.DATA?.experience?.experienceId,
                        label: res?.DATA?.experience?.experience
                    }
                    let selectedLocations = res?.DATA?.state?.map((item) => {
                        return {
                            value: item?.stateId,
                            label: item?.stateName
                        }
                    })
                    let selectedWorkMode = res?.DATA?.workMode?.map((item) => {
                        return {
                            value: item?.workModeId,
                            label: item?.workMode
                        }
                    })
                    let selectedJobType = res?.DATA?.jobType?.map((item) => {
                        return {
                            value: item?.jobTypeId,
                            label: item?.jobType
                        }
                    })
                    setSelectedEntries({
                        ...selectedEntries,
                        keyWordEntries: selectedKeywords,
                        experienceEntry: selectedExperience,
                        locationEntries: selectedLocations,
                        workModeEntries: selectedWorkMode,
                        jobTypeEntries: selectedJobType
                    })
                    setIsLoading(false)
                })
        }
    }, [])

    const handlePreferneces = () => {
        if (selectedEntries?.keyWordEntries?.length <= 0 || !selectedEntries?.keyWordEntries) {
            setWarning('Keywords are required.')
            return
        } else if (selectedEntries?.experienceEntry?.label?.length <= 0 || !selectedEntries?.experienceEntry?.label) {
            setWarning('Experience is required.')
            return
        } else if (selectedEntries?.locationEntries?.length <= 0 || !selectedEntries?.locationEntries) {
            setWarning('Job location is required.')
            return
        }
        console.log('>>>>>>>>>>>preferenceData',selectedEntries);
        
        const preferenceData = {
            skill: (selectedEntries?.keyWordEntries)?.map((item) => {
                return {
                    skillId: item?.value,
                    name: item?.label,
                };
            }),
            state: (selectedEntries?.locationEntries)?.map((item) => {
                return {
                    stateId: item?.value,
                    stateName: item?.label,
                };
            }),
            experience: {
                experienceId: selectedEntries?.experienceEntry?.value,
            },
            workMode:
                //     // (selectedEntries?.workModeEntries)?.map((item) => {
                //     //     return 
                selectedEntries?.workModeEntries?.value || selectedEntries?.workModeEntries?.length > 0 ? [{
                    workModeId: selectedEntries?.workModeEntries?.value ?? selectedEntries?.workModeEntries?.[0]?.value,
                    workMode: selectedEntries?.workModeEntries?.label ?? selectedEntries?.workModeEntries?.[0]?.label,
                }] : null,
            // // }),
            jobType:
                // (selectedEntries?.jobTypeEntries)?.map((item) => {
                // //     return
                selectedEntries?.jobTypeEntries?.value || selectedEntries?.jobTypeEntries?.length > 0 ? [{
                    jobTypeId: selectedEntries?.jobTypeEntries?.value ?? selectedEntries?.jobTypeEntries?.[0]?.value,
                    jobType: selectedEntries?.jobTypeEntries?.label ?? selectedCard?.jobTypeEntries?.[0]?.label,
                }] : null
            // }),
        }
        // console.log('>>>>>>>>>>>preferenceData', selectedEntries, '...', preferenceData)

        patchAPICall(Url.submitPrefernece, preferenceData)
            .then((res) => {
                if (res.SUCCESS) {
                    let preferenceSet = JSON.parse(sessionStorage.getItem('user')).preferenceSet
                    navigate('/Main/suggested', { state: { screen: 'suggested' } })
                    getAPICall(Url.getuser)
                        .then((res) => {
                            sessionStorage.setItem("user", JSON.stringify(res?.DATA));
                        })
                    setAlert(!preferenceSet ? 'Job Preference created successfully.' : 'Job Preference updated successfully.')
                } else {
                    setWarning(res?.message)
                }
            })
    }


    const handleInputChange = (e, field) => {
        if (field == "skill") {
            const updatedSkills = e.map((item) => ({
                value: typeof item.value === 'string' ? undefined : item.value,
                label: item.label
            }));
            setSelectedEntries({
                ...selectedEntries,
                ['keyWordEntries']: updatedSkills
            })
        } else if (field == "state") {
            setSelectedEntries({
                ...selectedEntries,
                ['locationEntries']: e
            })
        } else if (field == "experience") {
            setSelectedEntries({
                ...selectedEntries,
                ['experienceEntry']: e
            })
        } else if (field == "jobType") {
            setSelectedEntries({
                ...selectedEntries,
                ['jobTypeEntries']: e
            })
        } else if (field == "workMode") {
            setSelectedEntries({
                ...selectedEntries,
                ['workModeEntries']: e
            })
        }
    }
    // console.log('>>>>>>>>>>>', selectedEntries)
    return (
        <div className='suggestedJobsPrefMain'>
            <div className='suggestedJobsPrefImage'>
                <img src={preferenceImage} alt="" />
            </div>
            <div className='suggestedJobsPrefformDiv'>
                <img src={quikHireLogo} alt='' />
                <h2>{`${propsData?.state?.editFlow ? "Edit" : "Set"} Your Job Preferences`}</h2>
                <div className='inputFormPref'>
                    <div className='keyWordInput'>
                        <ComponentAndScreenConstant.SelectField
                            labelTxt="Keywords"
                            inputId="keyWordsSelect"
                            selectID={'KeywordDetails'}
                            selectedValue={!!selectedEntries?.keyWordEntries ? selectedEntries?.keyWordEntries : null}
                            onChange={(e) => handleInputChange(e, 'skill')}
                            inputWrapperStyle="keyWordsSelectWrapper"
                            placeholderTxt={"Select Keywords"}
                            selectOptions={allKeyWords}
                            // errMsg={errors?.skill}
                            isMultiValue={true}
                            menuPlacement={"auto"}
                            isCreatable={true}
                            isRequired={true}
                            maxSelected={10}
                        />
                    </div>
                    <div className='keyWordInput'>
                        <ComponentAndScreenConstant.SelectField
                            labelTxt="Experience"
                            inputId="experienceSelect"
                            selectedValue={!!selectedEntries?.experienceEntry ? selectedEntries?.experienceEntry : null}
                            onChange={(e) => handleInputChange(e, 'experience')}
                            inputWrapperStyle="experienceSelectWrapper"
                            placeholderTxt={"Select Experience"}
                            selectOptions={allExperience}
                            // errMsg={errors?.skill}
                            isMultiValue={false}
                            menuPlacement={"auto"}
                            isCreatable={false}
                            isRequired={true}
                        />
                    </div>
                    <div className='keyWordInput'>
                        <ComponentAndScreenConstant.SelectField
                            labelTxt="Location"
                            inputId="locationSelect"
                            selectedValue={!!selectedEntries?.locationEntries ? selectedEntries?.locationEntries : null}
                            onChange={(e) => handleInputChange(e, 'state')}
                            inputWrapperStyle="locationSelectWrapper"
                            placeholderTxt={"Select Location"}
                            selectOptions={allLocation}
                            selectID={'selectLocation'}
                            // errMsg={errors?.skill}
                            isMultiValue={true}
                            menuPlacement={"auto"}
                            isCreatable={false}
                            isRequired={true}
                            maxSelected={5}
                        />
                    </div>
                    <div className='keyWordInput'>
                        <ComponentAndScreenConstant.SelectField
                            labelTxt="Work Mode"
                            inputId="workModeSelect"
                            selectedValue={!!selectedEntries?.workModeEntries ? selectedEntries?.workModeEntries : null}
                            onChange={(e) => handleInputChange(e, 'workMode')}
                            inputWrapperStyle="workModeSelectWrapper"
                            placeholderTxt={"Select Work Mode"}
                            selectOptions={workMode}
                            // errMsg={errors?.skill}
                            isMultiValue={false}
                            menuPlacement={"top"}
                            isCreatable={false}
                            isRequired={false}
                        />
                    </div>
                    <div className='keyWordInput'>
                        <ComponentAndScreenConstant.SelectField
                            labelTxt="Job Type"
                            inputId="jobTypeSelect"
                            selectedValue={!!selectedEntries?.jobTypeEntries ? selectedEntries?.jobTypeEntries : null}
                            onChange={(e) => handleInputChange(e, 'jobType')}
                            inputWrapperStyle="jobTypeSelectWrapper"
                            placeholderTxt={"Select Job Type"}
                            selectOptions={jobType}
                            // errMsg={errors?.skill}
                            isMultiValue={false}
                            menuPlacement={"top"}
                            isCreatable={false}
                            isRequired={false}
                        />
                    </div>
                    <div className="submitBtnDiv" style={{ gap: "10px" }}>
                        <button type="button" className='submitBtn' onClick={() => navigate(-1)}>{/* <img src={quikWhiteLogo} alt="" /> */}Back</button>
                        <button type="button" className='submitBtn' onClick={handlePreferneces}>{/* <img src={quikWhiteLogo} alt="" /> */}{propsData?.state?.editFlow ? "Update" : "Submit"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuggestedJobsPref